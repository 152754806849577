// IMAGE GALLERY PLACEHOLDER FOR KINSMEN LOTTERY (LAST MINUTE FEATURE)
// Libraries
import React from 'react';

// Components
import Gallery from 'photoGallery/components/Gallery';


const PhotoGallery = () => (
  <Gallery />
)

export default PhotoGallery;
