// IMAGE GALLERY PLACEHOLDER FOR KINSMEN LOTTERY (LAST MINUTE FEATURE)
// Libraries
import React from 'react';
import ImageGallery from 'react-image-gallery';

// Styling
import 'react-image-gallery/styles/css/image-gallery.css';
// Library styling overrides
import './styles.css';

// Images
const images = [
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Entrance.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Entrance_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Entrance 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Entrance 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Entrance 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Entrance 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 5.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 5_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 5.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Living Room 5_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 5.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Room 5_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Kitchen.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Dining Kitchen_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 5.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen 5_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen Mudroom.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Kitchen Mudroom_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Mudroom.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Mudroom_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Mudroom 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Mudroom 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Mudroom 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Mudroom 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Laundry Room.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Laundry Room_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Laundry Room 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Laundry Room 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Powder Room.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Powder Room_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 5.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Reading Nook 5_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Bedroom.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Bedroom_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Bedroom 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Bedroom 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Bedroom 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Bedroom 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Closet.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Closet_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Closet 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Closet 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Closet 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Closet 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 5.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 5_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 6.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Master Ensuite 6_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 2 - 1.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 2 - 1_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bathroom.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bathroom_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bathroom 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bathroom 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Family Room 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Wet Bar.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Wet Bar_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Games Room.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Games Room_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Games Room 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Games Room 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Games Room 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Games Room 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Playhouse.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Playhouse_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Playhouse 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Playhouse 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Playhouse 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Playhouse 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 4 - 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Bedroom 4 - 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Workout Room.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Workout Room_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Workout Room 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Workout Room 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Front Exterior.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Front Exterior_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Front Exterior 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Front Exterior 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Front Exterior 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Front Exterior 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior 2.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior 2_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior 3.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior 3_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior 4.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Rear Exterior 4_thumb.jpg'
  },
  {
    original: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Garage.jpg',
    thumbnail: 'https://media.kinsmenhomelottery.com/2019/gallery/grand-prize/Garage_thumb.jpg'
  }
];

const Gallery = () => <ImageGallery items={images} showBullets />;

export default Gallery;
